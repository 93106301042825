<template>
  <div>
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            ข้อมูลหน้าหลัก
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white">
        <b-row>
          <b-col>
            <b-button v-b-toggle.collapse-general class="collapse-btn d-flex">
              <div class="flex-grow-1">ข้อมูลทั่วไป</div>
              <div class="d-flex mx-2">
                แก้ไขล่าสุด {{ new Date() | moment($formatDateTime) }}
              </div>
              <font-awesome-icon
                icon="chevron-right"
                class="icon float-right mt-1"
              />
              <font-awesome-icon
                icon="chevron-down"
                class="icon float-right mt-1"
              />
            </b-button>
            <b-collapse id="collapse-general" visible>
              <b-card class="card-box mb-0 border-0">
                <b-row>
                  <b-col cols="6">
                    <InputText
                      :textFloat="'ชื่อ'"
                      :placeholder="'ชื่อ'"
                      type="text"
                      isRequired
                      name="name"
                      v-model="form.name"
                    />
                    <!-- :isValidate="$v.form.name.$error"
                    :v="$v.form.name" -->
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <DateTimePicker
                      textFloat="วันที่เริ่มต้น"
                      type="datetime"
                      isTime
                      isRequired
                      placeholder="กรุณาเลือกวันที่และเวลา"
                      v-model="form.startDateTime"
                    />
                    <!-- :isValidate="$v.form.startDateTime.$error"
                    :v="$v.form.startDateTime" -->
                  </b-col>
                  <b-col md="6">
                    <DateTimePicker
                      textFloat="วันที่สิ้นสุด"
                      type="datetime"
                      isTime
                      isRequired
                      placeholder="กรุณาเลือกวันที่และเวลา"
                      v-model="form.endDateTime"
                    />
                    <!-- :isValidate="$v.form.endDateTime.$error"
                    :v="$v.form.endDateTime" -->
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button v-b-toggle.collapse-section class="collapse-btn">
              Home Section
              <font-awesome-icon
                icon="chevron-right"
                class="icon float-right mt-1"
              />
              <font-awesome-icon
                icon="chevron-down"
                class="icon float-right mt-1"
              />
            </b-button>
            <b-collapse id="collapse-section" visible>
              <b-card class="card-box mb-0 border-0">
                <b-row class="no-gutters mb-3">
                  <b-col class="text-right d-flex justify-content-end">
                    <b-input-group class="panel-input-serach">
                      <b-form-input
                        class="input-serach"
                        placeholder="ค้นหา"
                        v-model="filter.search"
                        @keyup="handleSearch"
                      ></b-form-input>
                      <b-input-group-prepend @click="btnSearch">
                        <span class="icon-input m-auto pr-2">
                          <font-awesome-icon icon="search" title="View" />
                        </span>
                      </b-input-group-prepend>
                    </b-input-group>
                    <b-button class="btn-main" @click="importFile"
                      >นำเข้าข้อมูล</b-button
                    >
                    <b-dropdown
                      class="ml-2 lang-dd border-0"
                      toggle-class="rounded-0"
                      right
                    >
                      <template #button-content> สร้าง </template>
                      <b-dropdown-item :to="`/setting-home/details/0/banner`">
                        แบนเนอร์
                      </b-dropdown-item>
                      <b-dropdown-item :to="`/setting-home/details/0/banner`">
                        สินค้าขายดี
                      </b-dropdown-item>
                      <b-dropdown-item :to="`/setting-home/details/0/banner`">
                        สินค้ามาใหม่
                      </b-dropdown-item>
                      <b-dropdown-item :to="`/setting-home/details/0/banner`">
                        รายการสินค้า
                      </b-dropdown-item>
                      <b-dropdown-item :to="`/setting-home/details/0/banner`">
                        บทความ
                      </b-dropdown-item>
                      <b-dropdown-item :to="`/setting-home/details/0/banner`">
                        เซ็คชั่น
                      </b-dropdown-item>

                      <!-- <b-dropdown-item @click="downloadTemplate">
                        ดาวน์โหลดไฟล์ต้นฉบับ
                      </b-dropdown-item> -->
                    </b-dropdown>
                  </b-col>
                </b-row>
                <b-row class="no-gutters">
                  <b-col>
                    <b-table
                      striped
                      responsive
                      hover
                      :items="items"
                      :fields="fields"
                      :busy="isBusy"
                      show-empty
                      empty-text="ไม่พบข้อมูล"
                      class="table-list"
                    >
                      <template v-slot:cell(startDateTime)="data">
                        <span>{{
                          new Date(data.item.startDateTime)
                            | moment($formatDate)
                        }}</span>
                      </template>
                      <template v-slot:cell(endDateTime)="data">
                        <span>{{
                          new Date(data.item.endDateTime) | moment($formatDate)
                        }}</span>
                      </template>
                      <template v-slot:cell(display)="data">
                        <div
                          v-if="data.item.display == true"
                          class="text-success"
                        >
                          ใช้งาน
                        </div>
                        <div v-else class="text-danger">ไม่ใช้งาน</div>
                      </template>
                      <template v-slot:cell(id)="data">
                        <div class="d-flex justify-content-center">
                          <router-link
                            :to="'/setting-home/details/' + data.item.id"
                          >
                            <b-button variant="link" class="text-dark p-0">
                              แก้ไข
                            </b-button>
                          </router-link>
                        </div>
                      </template>
                      <template v-slot:table-busy>
                        <div class="text-center text-black my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong class="ml-2">Loading...</strong>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <b-row class="no-gutters px-3 px-sm-0">
                  <b-col
                    class="form-inline justify-content-center justify-content-sm-between"
                  >
                    <div class="d-sm-flex m-3">
                      <b-pagination
                        v-model="filter.PageNo"
                        :total-rows="rows"
                        :per-page="filter.PerPage"
                        class="m-md-0"
                        @change="pagination"
                        align="center"
                      ></b-pagination>
                    </div>
                    <b-form-select
                      class="mr-sm-3 select-page"
                      v-model="filter.PerPage"
                      @change="hanndleChangePerpage"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
        <div class="p-3">
          <b-row>
            <b-col>
              <label class="font-weight-bold main-label">
                สถานะการแสดงผล
                <span class="text-danger">*</span>
              </label>
              <div>
                <b-form-checkbox
                  switch
                  v-model="form.display"
                  class="radio-active"
                  size="lg"
                >
                  <span class="ml-2 main-label">{{
                    form.display ? "ใช้งาน" : "ไม่ใช้งาน"
                  }}</span>
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col md="6">
              <b-button
                v-if="isEdit"
                class="btn btn-danger btn-details-set mr-md-2"
                :disabled="isDisable"
                @click="openModalDelete(form.translationList[0].name)"
                >ลบ</b-button
              >
              <b-button
                :href="`/setting-home`"
                :disabled="isDisable"
                class="btn-details-set btn-cancel"
                >ย้อนกลับ</b-button
              >
            </b-col>
            <b-col md="6" class="text-sm-right">
              <button
                type="button"
                @click="checkForm(0)"
                :disabled="isDisable"
                class="btn btn-main btn-details-set ml-md-2 text-uppercase"
              >
                บันทึก
              </button>
              <button
                type="button"
                @click="checkForm(1)"
                :disabled="isDisable"
                class="btn btn-main btn-details-set ml-md-2 text-uppercase"
              >
                บันทึกและออก
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalImportFile
      ref="modalImportFile"
      path="product/ImportBundleExcel"
      colorBtnConfirm="primary"
      @success="importSuccess"
    />
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import InputText from "@/components/inputs/InputText";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import ModalImportFile from "@/components/home/ModalImportFile";
import { required } from "vuelidate/lib/validators";

export default {
  name: "SettingHomeDetail",
  components: {
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    InputText,
    DateTimePicker,
    ModalImportFile,
  },
  data() {
    return {
      id: this.$route.params.id,
      timer: null,
      isLoadingData: false,
      isLoadingImage: false,
      isDisable: false,
      modalMessage: "",
      form: {
        name: "",
      },
      fields: [
        {
          key: "name",
          label: "ชื่อ",
          tdClass: "text-left",
        },
        {
          key: "type",
          label: "รูปแบบ",
          class: "mxw-150px",
        },
        {
          key: "display",
          label: "สถานะการแสดง",
          class: "mxw-150px",
        },
        {
          key: "sortOrder",
          label: "ลำดับการแสดง",
          class: "mxw-150px",
        },
        {
          key: "id",
          label: "",
          class: "mxw-100px",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        Search: "",
        OverView: "",
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      isEdit: false,
    };
  },
  validations: {
    // img: { required },
  },
  created: async function () {
    await this.getDatas();
  },
  methods: {
    getDatas: async function () {
      this.isLoadingData = true;

      // let resData = await this.$callApi(
      //   "get",
      //   `${this.$baseUrl}/api/setting/getLogo`,
      //   null,
      //   this.$headers,
      //   null
      // );
      // if (resData.result == 1) {
      //   this.form = resData.detail;
      //   if (this.form.id > 0) {
      //     this.isEdit = true;
      //   }
      // }
      this.$isLoading = true;
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let body = { base64: this.img };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/setting/saveLogo`,
        null,
        this.$headers,
        body
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
      } else {
        this.modalMessage = data.detail[0];
        this.$refs.modalAlertError.show();
      }
    },
    handleSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.PageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getList();
    },
    getList: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/banner/List`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        this.statusList = resData.detail.overviewCount;

        this.isBusy = false;
        this.$isLoading = true;
      }
    },
    pagination(Page) {
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
    importFile() {
      this.$refs.modalImportFile.show();
    },
    importSuccess: async function (data) {
      let selectedData = data.detail;
      if (this.productGroups.length > 0) {
        for (let index = 0; index < selectedData.length; index++) {
          let find = this.productGroups.findIndex((e) => {
            return e.id === selectedData[index].id;
          });
          if (find === -1)
            this.productGroups.push({ ...selectedData[index], isAddNew: true });
        }
      } else this.productGroups = selectedData;

      for (let index = 0; index < this.productGroups.length; index++) {
        let newDataIndex = this.newData.findIndex((e) => {
          return e.id === this.productGroups[index].id;
        });
        if (newDataIndex === -1) {
          this.newData.push({
            id: this.productGroups[index].id,
            price: "",
            storePrice: "",
          });
        }
      }
      for (let index = 0; index < this.newData.length; index++) {
        let selectIndex = selectedData.findIndex((e) => {
          return e.id === this.newData[index].id;
        });
        if (selectIndex > -1) {
          this.newData[index].price = selectedData[selectIndex].memberPrice;
          this.newData[index].storePrice = selectedData[selectIndex].storePrice;
        }
      }
    },
  },
};
</script>

<style scoped>
::v-deep .dropdown-menu-right {
  padding: 0rem !important;
}
::v-deep .lang-dd ul {
  width: 100px;
}
.panel-input-serach {
  border: 1px solid #d8dbe0 !important;
}
</style>
